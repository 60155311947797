:root {
  --white: #ffffff;
  --dark-blue: #10213f;
  --text-blue: #345365;
  --light-blue: #c7e4f6;
  --bgc-blue: #edf6fd;
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
p {
  direction: rtl;
}

body,
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bg-light-blue {
  background-color: var(--bgc-blue);
}

.dark-blue {
  color: var(--dark-blue);
}

.text-blue {
  color: var(--text-blue);
}

.title {
  color: var(--text-blue);
  text-align: center;
  font: normal normal 600 5rem Rubik;
  margin-bottom: 3rem;
}

.mini-title {
  color: var(--dark-blue);
  font: normal normal normal 2rem Rubik;
  margin-bottom: 0.2em;
}

.text {
  color: var(--dark-blue);
  font: normal normal normal 1.3rem Rubik;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (max-width: 900px) {
  .title {
    font-size: 4rem;
    margin-bottom: 0.2em;
  }
}
