/* Header */
.header {
  position: sticky;
  top: 0;
  background-color: var(--light-blue);
  text-align: right;
  padding: 0.4em 1em 0.4em 0;
  z-index: 5;
}

/* Welcome page  */
.logo {
  width: 16.6875rem;
}

.split-welcome {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  justify-content: space-around;
  max-width: 2000px;
  margin-inline: auto;
  padding: 5em 8em;
}

.text-button-div {
  text-align: right;
  margin-left: 6em;
}

.dark-blue.haed {
  font: normal normal 600 6.25rem Rubik;
  margin-bottom: 0.3em;
}

.dark-blue.head-2 {
  font: normal normal normal 4.0625rem Rubik;
  margin-bottom: 0.8em;
}

.MuiButton-root.btn,
.MuiButton-root.btn:hover {
  background-color: #009dff;
  border-radius: 15px;
  font: normal normal 600 1.5rem Rubik;
  padding: 0.9em 1.8em;
  margin-bottom: 1em;
}

.text-blue.text {
  font: normal normal normal 1.25rem Rubik;
}

.dr-img {
  min-width: 200px;
  min-height: 200px;
  max-width: 700px;
  max-height: 700px;
  border-radius: 150px;
}

/* Services section */
.services-section {
  margin: 4em;
  max-width: 2000px;
  margin-inline: auto;
}

.split-services {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
}

.service-item {
  direction: rtl;
  padding: 2em;
  margin: 0 1em 2.5em 1em;
  border-radius: 30px;
  width: 28%;
  background-color: rgba(237, 246, 253, 0.6);
  cursor: pointer;
  flex-shrink: 0;

  scale: 0.8;
  opacity: 0;
  animation: fade-in linear forwards;
  animation-timeline: view();
  animation-range: entry;
}

.services-img {
  margin-bottom: 2em;
}

.popup-item {
  padding: 0em 2em 2em 2em;
  direction: rtl;
}

.popup-item > img {
  display: block;
  width: 60%;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
}

@keyframes popup-fade-in {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: popup-fade-in 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9)
    forwards;
  animation: popup-fade-in 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

/* Good things section */
.good-things-div {
  padding: 15em 0 13em 0;
  gap: 2.5%;
  text-align: right;
}

.split-good-things {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
}

.line-background {
  background-image: url("./images/line-bg-blue.svg");
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 2065px;
  margin-inline: auto;
}

.good-things-card {
  background-color: var(--white);
  box-shadow: 0px 20px 40px #00000029;
  border-radius: 30px;
  padding: 2.4em 2.3em;
  width: 25%;

  scale: 0.8;
  opacity: 0;
  animation: fade-in linear forwards;
  animation-timeline: view();
  animation-range: entry;
}

.icon-good-things {
  width: 5rem;
  margin-bottom: 1rem;
}

/* About */
.about-split {
  padding: 5em 10em 10em 10em;
  display: flex;
  flex-direction: row-reverse;
  gap: 4em;
}

.video-div {
  width: 60%;
}

.video {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border-radius: 25px;
  border: 5px solid var(--text-blue);
}

.video::before {
  padding-top: 56.25%;
  display: block;
  content: "";
  box-sizing: border-box;
}

iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  box-shadow: 0px 1rem 2.5rem #00000029;
}

.text-about-div {
  width: 40%;
  text-align: right;
}

.title-about {
  font: normal normal 600 2.5rem Rubik;
  margin-bottom: 0.5em;
}

.text-about {
  font: normal normal normal 1.55rem Rubik;
  line-height: 1.3;
  text-align: right;
}

/* Comments */
.comments-div {
  padding: 5em 0 8em 0;
  overflow: hidden;
}

.slick-track {
  margin-top: 1rem;
}

.slick-slide {
  padding-inline: 2.5em;
}

.card-comment {
  min-height: 23rem;
  text-align: right;
  margin-bottom: 2.5em;
  padding: 2em 2.3em;
  box-shadow: 0px 1rem 2.5rem #00000029;
  border-radius: 30px;
}

.name-comment {
  color: var(--text-blue);
  font: normal normal normal 1.5rem Rubik;
  margin-bottom: 0.7em;
}

.text-comment {
  font: normal normal normal 1.8rem Rubik;
  line-height: 1.3;
  text-align: right;
}

.slick-dots li button:before {
  color: var(--light-blue) !important;
  opacity: 1 !important;
  font-size: 1.25rem !important;
  cursor: pointer;
}

.slick-dots li.slick-active button:before {
  color: var(--dark-blue) !important;
}

/* Contact */
.contact-div {
  padding: 5em;
  text-align: center;
  margin-inline: auto;
  max-width: 1600px;
}

.p-contact {
  font: normal normal normal 1.55rem Rubik;
  line-height: 1.3;
  margin-bottom: 3.3em;
  margin-inline: 5em;
}

.contant-split {
  display: flex;
  justify-content: center;
  gap: 2em;
  flex-direction: row-reverse;
  flex-wrap: wrap;
}

.contact-card {
  background-color: var(--white);
  padding: 2em 0em;
  width: 31%;
  cursor: pointer;
  border-radius: 30px;
  max-width: 570px;
  border: 0.4rem solid transparent;
  transition: border 300ms ease;

  scale: 0.8;
  opacity: 0;
  animation: fade-in linear forwards;
  animation-timeline: view();
  animation-range: entry;
}

.contact-card:hover {
  border: 0.4rem solid var(--text-blue);
}

.contact-card > svg {
  width: 7rem;
  margin-bottom: 1em;
}

.p-contact-card {
  font: normal normal normal 1.6rem Rubik;
}

/* footer */
.footer-bg {
  background-color: var(--dark-blue);
}

.footer-split {
  color: var(--white);
  background-image: url("./images/line-transparent.svg");
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 2100px;
  margin-inline: auto;

  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  gap: 7%;
  padding: 7em;
}

.col-footer > h2 {
  font: normal normal bold 1.875rem Rubik;
  margin-bottom: 1em;
}

.col-footer > p {
  font: normal normal normal 1.5625rem Rubik;
  margin-top: 1.4em;
  cursor: pointer;
}

.col-footer > p:hover,
.col-footer > p:focus {
  opacity: 0.5;
}

.img-col {
  width: 26%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.logo-footer {
  width: 17rem;
  margin-bottom: 7rem;
}

.img-col > p {
  font: normal normal normal 1.3rem/1.6rem Rubik;
}

/* Footer bootom */
.bg-footer-bottom {
  background-color: #040f23;
  padding: 0.7em 0;
}

.footer-bottom {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  max-width: 2000px;
  margin-inline: auto;
  text-align: right;
}

.svg-div {
  display: flex;
  flex-direction: row-reverse;
  gap: 1.8em;
  margin-left: 5em;
}

.p-footer-bootom {
  color: var(--white);
  margin-right: 5em;
}

.svg-div > svg {
  cursor: pointer;
}

.svg-div > svg:hover {
  opacity: 0.5;
}

@media (min-width: 1315px) {
  html {
    font-size: 15.5px;
  }

  .dr-img {
    max-width: 600px;
  }
}

@media (max-width: 1315px) {
  html {
    font-size: 11px;
  }

  .dr-img {
    max-width: 320px;
    border-radius: 90px;
  }
}

@media (max-width: 1180px) {
  .dark-blue.haed {
    font: normal normal 600 5rem Rubik;
    line-height: 1;
  }

  .dark-blue.head-2 {
    font: normal normal normal 3rem Rubik;
  }
}

/* Display bloack */
@media (max-width: 900px) {
  html {
    font-size: 10px;
  }

  .header {
    position: static;
  }

  /* Welcome */
  .split-welcome {
    display: block;
    padding: 2.5em 4em;
  }

  .text-button-div {
    text-align: center;
    margin: 0 0 4em 0;
    width: 100%;
  }

  .div-img-welcome {
    width: 100%;
    max-width: 270px;
    margin-inline: auto;
    min-width: 200px;
  }

  .dr-img {
    border-radius: 70px;
  }

  /* Services */
  .split-services {
    display: block;
  }

  .service-item {
    width: 100%;
    text-align: center;
    padding: 2em;
    margin: 0 0em 2.5em 0em;
    max-width: 500px;
    margin-inline: auto;
  }

  .services-img {
    margin-bottom: 1.3em;
    width: 60%;
    max-width: 300px;
  }

  /* Good things */
  .good-things-div {
    padding: 5em 2em 0em 2em;
    text-align: center;
  }

  .good-things-card {
    width: 100%;
    max-width: 400px;
    padding: 0.5em 1.3em 1.7em 1.3em;
  }

  .good-things-card + .good-things-card {
    margin-top: 3em;
  }

  .icon-good-things {
    width: 4rem;
    margin-bottom: 0rem;
  }

  /* about */
  .about-split {
    padding: 5em 2em;
    display: block;
  }

  .text-about-div {
    width: 100%;
    margin-bottom: 3em;
    max-width: 400px;
    margin-inline: auto;
  }

  .video-div {
    width: 100%;
    margin-inline: auto;
    max-width: 400px;
  }

  /* comments */
  .comments-div {
    padding-bottom: 5em;
  }

  .name-comment {
    font-size: 1.3rem;
  }

  .text-comment {
    font-size: 1.5rem;
  }

  /* contact */
  .contact-div {
    padding: 2.5em 1.3em;
  }

  .p-contact {
    margin-bottom: 2em;
    margin-inline: 0em;
  }

  .p-contact-card {
    font: normal normal normal 1.2rem Rubik;
  }

  .contant-split {
    gap: 1em;
  }

  .contact-card {
    padding: 0em 0 1.5em 0;
    width: 48%;
    max-width: 250px;
  }

  .contact-card > svg {
    width: 5em;
    margin-bottom: 0;
  }

  /* footer */
  .footer-split {
    flex-direction: column;
    padding: 7em 4em;
    text-align: center;
  }

  .col-footer {
    margin-bottom: 6em;
  }

  .img-col {
    width: 100%;
  }

  .logo-footer {
    margin: 0em auto 2.5em auto;
  }

  .btn.btn-footer {
    margin-inline: auto;
  }

  .img-col > p {
    margin-inline: auto;
  }

  /* footer bottom */

  .footer-bottom {
    gap: 1em;
  }

  .svg-div {
    margin-left: 1em;
    gap: 4%;
  }

  .svg-div > svg {
    width: 3rem;
  }

  .p-footer-bootom {
    margin: 0 1em 0 0;
  }
}

@keyframes fade-in {
  to {
    scale: 1;
    opacity: 1;
  }
}
